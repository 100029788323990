import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import TextImage from "../components/textImage"
import ImageColumn from "../components/imageColumn"
import Map from "../components/map"

const RoadToBakery = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "road-to-bakery-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index2: file(relativePath: { eq: "road-to-bakery-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "road-to-bakery-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index4: file(relativePath: { eq: "road-to-bakery-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index5: file(relativePath: { eq: "road-to-bakery-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index6: file(relativePath: { eq: "road-to-bakery-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index7: file(relativePath: { eq: "road-to-bakery-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index8: file(relativePath: { eq: "road-to-bakery-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index9: file(relativePath: { eq: "road-to-bakery-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Road to Bakery"/>
      <div data-srgrid-hide="xs-s">
        <Map/>
      </div>
      <TextImage headline={"Road to Bakery."} source={data.index1.childImageSharp.fluid} source_position={"top"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Unsere Brote spiegeln meine Reise wider. Ich durfte viele Orte, wie auf der Karte zu sehen, Bäckereien und
              Menschen kennenlernen, die alle ihre ganz eigenen, besonderen Brote backen.
              <br/>
              <br/>
              Einige davon habe ich als
              Anhaltspunkt genommen und für meine eigene Bäckerei neu interpretiert. Aber wo sie herkommen, das werde
              ich nicht vergessen. Das alles war meine persönliche #roadtobakery die mich nach Bonn gebracht hat.
              <br/>
              <br/>
              Euer
              Max
            </p>
          </div>
        </div>
      </TextImage>
      <ImageColumn source_left={data.index2.childImageSharp.fluid}
                   source_right={data.index3.childImageSharp.fluid}/>
      <HeroImage source={data.index4.childImageSharp.fluid}
                 text={"Wiedersehensfreude. Zu Besuch <br /> bei Königsback im Schwabenländle."}
                 text_position={"bottom-left"}/>
      <ImageColumn source_left={data.index5.childImageSharp.fluid}
                   figcaption_right={"Einer, der weiß, wie es geht. <br />Bäcker Baier in Herrenberg."}
                   source_right={data.index6.childImageSharp.fluid}/>
      <HeroImage source={data.index7.childImageSharp.fluid}
                 text={"Eine prägende Zeit. <br /> Bäcker Hansen auf Föhr. "}
                 text_position={"bottom-left"}/>
      <div data-srgrid-hide="m-xl">
        <Map/>
      </div>
      <div data-srgrid-row="center">
        <div data-srgrid-col="xs-24 m-20">
          <HeroImage source={data.index8.childImageSharp.fluid} wrapper={true}/>
        </div>
      </div>
      <HeroImage source={data.index9.childImageSharp.fluid}/>
    </Layout>
  )
}


export default RoadToBakery
