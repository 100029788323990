import React from "react"
import MapMobile from "../images/map/map-mobile.png"
import MapTablet from "../images/map/map-tablet.png"
import { withBreakpoints } from "react-breakpoints"

const Map = ({ breakpoints, currentBreakpoint }) => {
  return (
    <div className={"map"}>
      {(breakpoints[currentBreakpoint] <= breakpoints.s) ? (
        <img src={MapMobile} alt=""/>
      ) : (
        <img src={MapTablet} alt=""/>
      )}
    </div>
  )
}

export default withBreakpoints(Map)